/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AmiciziaUtentiDTO } from './AmiciziaUtentiDTO';
import {
    AmiciziaUtentiDTOFromJSON,
    AmiciziaUtentiDTOFromJSONTyped,
    AmiciziaUtentiDTOToJSON,
} from './AmiciziaUtentiDTO';

/**
 * 
 * @export
 * @interface RichiestaAmiciziaDTO
 */
export interface RichiestaAmiciziaDTO {
    /**
     * 
     * @type {AmiciziaUtentiDTO}
     * @memberof RichiestaAmiciziaDTO
     */
    amiciziaUtentiDTO?: AmiciziaUtentiDTO;
    /**
     * 
     * @type {string}
     * @memberof RichiestaAmiciziaDTO
     */
    messaggio?: string;
}

/**
 * Check if a given object implements the RichiestaAmiciziaDTO interface.
 */
export function instanceOfRichiestaAmiciziaDTO(value: object): value is RichiestaAmiciziaDTO {
    return true;
}

export function RichiestaAmiciziaDTOFromJSON(json: any): RichiestaAmiciziaDTO {
    return RichiestaAmiciziaDTOFromJSONTyped(json, false);
}

export function RichiestaAmiciziaDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RichiestaAmiciziaDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'amiciziaUtentiDTO': json['amiciziaUtentiDTO'] == null ? undefined : AmiciziaUtentiDTOFromJSON(json['amiciziaUtentiDTO']),
        'messaggio': json['messaggio'] == null ? undefined : json['messaggio'],
    };
}

export function RichiestaAmiciziaDTOToJSON(value?: RichiestaAmiciziaDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amiciziaUtentiDTO': AmiciziaUtentiDTOToJSON(value['amiciziaUtentiDTO']),
        'messaggio': value['messaggio'],
    };
}

