import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Chip, CircularProgress} from "@mui/material";
import {toast} from "react-toastify";
import styles from '../Dettagli-Utente/DettagliUtente.module.css';
import {
    CollectionByIdUtenteRequest,
    CollezioneRestApi,
    GetAmiciziaRequest,
    UtenteDTO,
    UtenteRestApi
} from '../../../api';
import {FaUserCheck, FaUserClock, FaUserPlus} from "react-icons/fa";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import {formatDate, transformCardType} from "../../../utility/utils";
import UserCollection from "../../Card/User-collection/UserCollection";
import {aggiungiAmico} from "../../../store/collezioni/CollezioniHandlers";

const apiUtente = new UtenteRestApi();
const apiCollezioni = new CollezioneRestApi();

const DettagliUtente: React.FC = () => {
    const { idUser, idCollection } = useParams<{ idUser: string | undefined, idCollection: string | undefined }>();
    const [utente, setUtente] = useState<UtenteDTO | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [collezioni, setCollezioni] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            if (!idUser) {
                setError("ID utente mancante.");
                setLoading(false);
                return;
            }

            try {
                const user = JSON.parse(localStorage.getItem('user') || '{}');
                const request: GetAmiciziaRequest = {idUser: user.id, idFriend: idUser };
                const requestCollections:  CollectionByIdUtenteRequest = {userId: idUser}

                const [responseUtente, responseCollezioni] = await Promise.all([
                    apiUtente.getAmiciziaRaw(request),
                    apiCollezioni.collectionByIdUtenteRaw(requestCollections)
                ]);

                const jsonUtente = await responseUtente.raw.json();
                const jsonCollezioni = await responseCollezioni.raw.json();

                if (!jsonUtente || !jsonUtente.id) {
                    setError("Utente non trovato.");
                } else {
                    setUtente(jsonUtente as UtenteDTO);
                    setCollezioni(jsonCollezioni || []);
                }
            } catch (err) {
                console.error("Errore nel recupero dei dati:", err);
                setError("Impossibile recuperare i dettagli dell'utente e/o le sue collezioni. Riprova più tardi.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [idUser]);

    const handleAggiungiAmico = async () => {
        if (!idUser || !utente) return;

        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const success = await aggiungiAmico(user.id, idUser);
        if (success) {
            toast.success("Richiesta di amicizia inviata con successo!");
            setUtente({...utente, richiestaInviata: true});
        } else {
            toast.error("Impossibile inviare la richiesta. Riprova più tardi.");
        }
    };

    if (loading) {
        return (
            <div className={styles.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        toast.error(error);
        return <div className={styles.errorContainer}>{error}</div>;
    }

    if (!utente) {
        return <div className={styles.errorContainer}>Utente non trovato.</div>;
    }

    const breadcrumbItems = idCollection
        ? [
            {
                name: 'Dettagli Collezione',
                link: `/collections-user-detail/${idCollection}`,
            },
            { name: utente?.username ?? 'Sconosciuto' },
        ]
        : [
            {
                name: 'Tutti i Post',
                link: '/all-posts',
            },
            { name: utente?.username ?? 'Sconosciuto' },
        ];


    return (
        <>
            <Breadcrumb items={breadcrumbItems}/>
            <div className={styles.profileContainer}>
                <div className={styles.profileHeader}>
                    <div className={styles.avatar}></div>
                    <div className={styles.userInfo}>
                        <h1 className={styles.collectionName}>{utente.username}</h1>
                        <div className={styles.username}>
                            <Chip
                                label={<em>{utente.nome} {utente.cognome}</em>}
                                className={styles.chip}
                                variant="outlined"
                                sx={{
                                    backgroundColor: '#424242',
                                    color: '#fff',
                                    borderColor: '#fff',
                                    borderWidth: 1,
                                    borderRadius: '20px',
                                    padding: '6px 12px',
                                    pointerEvents: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#333',
                                        borderColor: '#fff',
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.exportButtonContainer}>
                        <button
                            className={`${styles.exportButton} ${(utente.amici || utente.richiestaInviata) ? styles.disabledButton : ''}`}
                            disabled={utente.amici || utente.richiestaInviata}
                            onClick={handleAggiungiAmico}
                        >
                            {utente.amici ? (
                                <>
                                    <FaUserCheck style={{marginRight: '8px'}}/>
                                    Siete già amici.
                                </>
                            ) : utente.richiestaInviata ? (
                                <>
                                    <FaUserClock style={{marginRight: '8px'}}/>
                                    Richiesta già inviata
                                </>
                            ) : (
                                <>
                                    <FaUserPlus style={{marginRight: '8px'}}/>
                                    Aggiungi Amico
                                </>
                            )}
                        </button>

                    </div>
                </div>
                <div className={styles.statsContainer}>
                    <div className={styles.stats}>
                        <span>EMAIL</span>
                        <span>{utente.email ?? 'N/A'}</span>

                    </div>
                    <div className={styles.stats}>
                        <span>PUNTEGGIO</span>
                        <span>{utente.punteggioCliente}</span>
                    </div>
                    <div className={styles.stats}>
                        <span>UTENTE DAL</span>
                        <span>{utente.dataCreazione ? formatDate(utente.dataCreazione) : ''}</span>
                    </div>
                </div>
                <div className={styles.profileContainer}>
                    {collezioni.map((collection, index) => (
                        <UserCollection
                            key={index}
                            nomeCollezione={collection.nome}
                            tipoCollection={transformCardType(collection.tipoCard)}
                            nomeUtente={collection.utente.username}
                            id={collection.id}
                            imgCopertina={collection.imgCopertina}
                            idUser={collection.utente.id}/>
                    ))}
                </div>
            </div>
        </>
    );
};

export default DettagliUtente;
