import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import FilterAndSort from '../components/Card/Filtri-ricerca/filtri-ricerca';
import '../styles/CollectionsPublic.css';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import {CollezioneCriteria, CollezioneRestApi, FindByCriteriaRequest, PubblicCollectionRequest} from '../api';
import {Box, Card, CardContent, CircularProgress, Typography} from "@mui/material";
import ListaCollezioniPubbliche from "../components/Collection/Lista-collezioni-pubbliche";
import {People} from '@mui/icons-material';
import FiltriRicercaCollezioni from "../components/Collection/Filtri-ricerca-collezioni";

const api = new CollezioneRestApi();

export const StyledCard = styled(Card)(({theme}) => ({
    marginBottom: "50px",
    borderRadius: '35px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
}));

const theme = createTheme({
    components: {
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#3f51b5',
                    '&:hover': {
                        backgroundColor: '#303f9f',
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#1a237e',
                    },
                },
            },
        },
    },
});

const ListCollectionsPublic: React.FC = () => {
    const [filter, setFilter] = useState<any>({});
    const [collections, setCollections] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userId, setUserId] = useState('');

    const fetchCollection = async (newFilter: any, idUser: string, page: number) => {
        setIsLoading(true);
        setError(null);

        const pageableForFindCriteria = {
            pageNumber: page - 1,
            pageSize: 15,
        };

        try {
            let jsonResponse;
            const user = JSON.parse(localStorage.getItem('user') || '{}');

            const utenteCriteria = {
                ...((newFilter && newFilter.collezioneCriteria && newFilter.collezioneCriteria.utente) || {}),
                id: {notEquals: user.id}
            };

            const criteria = (newFilter && newFilter.collezioneCriteria && newFilter.collezioneCriteria.criteria) || {};

            const finalCriteria = {
                ...criteria,
                privata: {equals: false},
                utente: utenteCriteria
            };
            const collezioniCriteria: CollezioneCriteria = {
                nome: newFilter.collezioneCriteria?.criteria.name,
                tipoCard: newFilter.collezioneCriteria?.criteria.tipoTcg,
                privata: {equals: false},
                utente: utenteCriteria,
            };

            const searchRequest = {
                criteria: finalCriteria,
                collezioneCriteria: collezioniCriteria,
                pageable: pageableForFindCriteria
            };

            const request: FindByCriteriaRequest = {searchRequest};
            console.log("Search request", request);

            const response = await api.findByCriteriaRaw(request);
            jsonResponse = await response.raw.json();

            if (jsonResponse && jsonResponse.content) {
                setCollections(jsonResponse.content);
                setTotalPages(jsonResponse.totalPages);
            } else {
                setCollections([]);
                setTotalPages(0);
            }

        } catch (error) {
            console.error('Error fetching collection:', error);
            setError('Errore durante il recupero delle collezioni');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        if (user && user.id) {
            setUserId(user.id);
            fetchCollection(filter, user.id, currentPage);
        }
    }, [currentPage, filter]);

    const handleFilter = (newFilter: any) => {
        // newFilter viene dal figlio nella forma { name: {contains: ...}, username: {contains: ...}, tipoTcg: {equals: ...} }

        const utenteCriteria = newFilter.username ? {username: newFilter.username} : {};
        const collezioneCriteria = {
            criteria: {
                name: newFilter.name,      // {contains: ...} o undefined
                tipoTcg: newFilter.tipoTcg // {equals: ...} o undefined
            },
            utente: utenteCriteria
        };

        const finalFilter = {
            collezioneCriteria
        };

        setFilter(finalFilter);
        setCurrentPage(1);
        fetchCollection(finalFilter, userId, 1);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    return (
        <div style={{padding: '20px', marginTop: "20px"}}>
            <Box sx={{maxWidth: 1200, margin: '0 auto', padding: '20px'}}>
                <StyledCard variant="outlined" sx={{borderRadius: 3, boxShadow: 3}}>
                    <CardContent sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography
                            variant="h4"
                            sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}
                        >
                            <People sx={{marginRight: '8px', fontSize: '2.5rem', color: '#1976d2'}}/>
                            Vetrina Collezioni
                        </Typography>
                    </CardContent>
                </StyledCard>
            </Box>

            <div className="cards-page">
                <div className="content">
                    <div className="filters">
                        {error && <p className="error">{error}</p>}
                        <FiltriRicercaCollezioni onFilter={handleFilter} isCollection={true} collections={undefined}/>
                    </div>
                    <div className="card-list-container">
                        {isLoading ? (
                            <div className="loading-spinner">
                                <CircularProgress/>
                            </div>
                        ) : (
                            <>
                                <ListaCollezioniPubbliche
                                    filter={filter}
                                    collections={collections}/>
                                {totalPages > 1 && (
                                    <ThemeProvider theme={theme}>
                                        <Stack spacing={2} alignItems="center" sx={{marginTop: '20px'}}>
                                            <Pagination
                                                count={totalPages}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                variant="outlined"
                                                shape="rounded"
                                                renderItem={(item) => <PaginationItem {...item} />}
                                            />
                                        </Stack>
                                    </ThemeProvider>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListCollectionsPublic;
