import React, {useState} from 'react';
import {Button, Card, CardContent, createTheme, MenuItem, TextField, ThemeProvider,} from '@mui/material';
import {styled} from '@mui/system';
import {TipoCardFilterEqualsEnum} from "../../api";
import {transformCardType} from "../../utility/utils";
import {menuItemStyles} from "../Card/Filtri-ricerca/FiltriHandler";

interface FilterAndSortProps {
    onFilter: (filter: any) => void;
    isCollection: boolean;
    tipoCard?: any;
    initialFilters?: {
        sort?: string;
        name?: string;
        username?: string;
    };
    collections?: any[] | null;
}

const RicercaSpan = styled('span')`
    font-size: 23px;
    font-weight: bold;
    color: #3f51b5;
    padding: 8px 12px;
    margin-bottom: 20px;
    font-family: "Nunito", sans-serif;
`;

const FiltriRicercaCollezioni: React.FC<FilterAndSortProps> = ({
                                                                   onFilter,
                                                                   isCollection,
                                                                   tipoCard,
                                                                   initialFilters = {},
                                                                   collections,
                                                               }) => {
    const [name, setName] = useState(initialFilters.name || "");
    const [selectedTipo, setSelectedTipo] = useState<TipoCardFilterEqualsEnum | "">("");
    const [usernameUtente, setUsernameUtente] = useState<string>("");

    const tipo = Object.values(TipoCardFilterEqualsEnum);

    const handleFilter = () => {
        const criteria = {
            name: name ? { contains: name } : undefined,
            username: usernameUtente ? { contains: usernameUtente } : undefined,
            tipoTcg: selectedTipo ? { equals: selectedTipo } : undefined,
        };

        console.log("Criteri di ricerca generati:", criteria); // Debug
        onFilter(criteria);
    };

    const handleReset = () => {
        setName("");
        setSelectedTipo("");
        setUsernameUtente("");
        onFilter({});
    };

    return (
        <ThemeProvider theme={createTheme()}>
            <Card sx={{
                padding: '23px',
                margin: '20px',
                borderRadius: '50px',
                background: 'linear-gradient(135deg, #f9f9f9, #eaeaea)',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            }}>
                <CardContent>
                    <RicercaSpan>Ricerca Collezioni Utente</RicercaSpan>
                    <TextField
                        label="Nome Collezione"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        label="Username Utente"
                        value={usernameUtente}
                        onChange={(e) => setUsernameUtente(e.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        select
                        label="Tipo TCG"
                        value={selectedTipo}
                        onChange={(e) => setSelectedTipo(e.target.value as TipoCardFilterEqualsEnum)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    >
                        {tipo.map((tip, index) => (
                            <MenuItem key={index} value={tip} sx={menuItemStyles}>
                                {transformCardType(tip)}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Button
                        sx={{
                            marginTop: '20px',
                            borderRadius: '20px',
                            padding: '10px',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            fontFamily: '"Nunito", sans-serif',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
                            },
                        }}
                        onClick={handleFilter}
                        variant="contained"
                        color="warning"
                        fullWidth
                    >
                        FILTRA
                    </Button>
                    <Button
                        onClick={handleReset}
                        variant="contained"
                        color="error"
                        fullWidth
                        sx={{
                            marginTop: '20px',
                            borderRadius: '20px',
                            padding: '10px',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            fontFamily: '"Nunito", sans-serif',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
                            },
                        }}
                    >
                        RESET
                    </Button>
                </CardContent>
            </Card>
        </ThemeProvider>
    );
};

export default FiltriRicercaCollezioni;
