import React, { useState } from 'react';
import { MenuItem, Select, InputLabel, FormControl, Box, SelectChangeEvent } from '@mui/material';
import {menuItemStyles} from "../../Card/Filtri-ricerca/FiltriHandler";

interface SelectTipoCollezioneProps {
    onSelectionChange: (tipoCollezione: string) => void;
}

const SelectTipoCollezione: React.FC<SelectTipoCollezioneProps> = ({ onSelectionChange }) => {
    const [selectedTipo, setSelectedTipo] = useState<string>("");

    const tipiCollezione = ["One Piece", "DragonBall Fusion", "Lorcana"];

    const handleSelectionChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value as string;
        setSelectedTipo(value);
        console.log(value)
        onSelectionChange(value);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, padding: 2 }}>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="tipoCollezione-label">Tipo Collezione</InputLabel>
                <Select
                    labelId="tipoCollezione-label"
                    id="tipoCollezione"
                    value={selectedTipo}
                    onChange={handleSelectionChange}
                    label="Tipo Collezione"
                    sx={{
                        width: '250px',
                        backgroundColor: '#fff',
                        borderRadius: '4px',

                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ccc',
                            transition: 'border-color 0.2s ease',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#999',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1976d2',
                        },

                        color: '#333',

                        '&:hover': {
                            boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
                        }
                    }}
                >
                    <MenuItem value="">
                        <em>-- Seleziona un tipo --</em>
                    </MenuItem>
                    {tipiCollezione.map((tipo) => (
                        <MenuItem key={tipo} value={tipo} sx={menuItemStyles}>
                            {tipo}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default SelectTipoCollezione;
