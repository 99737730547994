import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {
    AddCardWishlistRequest,
    CarteRestApi, FindByCriteriaSpecificCardRequest,
    SearchRequest,
    TipoCardFilterEqualsEnum,
    WishlistRestApi, WishlistUtenteDTO
} from "../../api";
import {toast} from "react-toastify";

const api = new CarteRestApi();

const categoryMap: { [key: string]: TipoCardFilterEqualsEnum } = {
    'magic': TipoCardFilterEqualsEnum.MagicTheGathering,
    'pokemon': TipoCardFilterEqualsEnum.Pokemon,
    'yugioh': TipoCardFilterEqualsEnum.YugiOh,
    'one_piece': TipoCardFilterEqualsEnum.OnePiece,
    'dragonball': TipoCardFilterEqualsEnum.Dragonball,
    'lorcana': TipoCardFilterEqualsEnum.Lorcana,
};

interface CardState {
    cards: SearchRequest[];
    items: Array<{ cartaId: string; utenteId: string }>;
    totalPages: number;
    currentPage: number;
    error: string | null;
    isLoading: boolean;
}

const initialState: CardState = {
    cards: [],
    items: [],
    totalPages: 0,
    currentPage: 1,
    error: null,
    isLoading: false,
};

interface FetchCardsParams {
    categoryName: string | undefined;
    newFilter: {
        criteria: any;
        criteriaOp?: any;
        criteriaPokemon?: any;
        criteriaDragonballFs?: any;
        criteriaLorcana?: any;

    };
    sort?: any;
    page: number;
}

const wishListApi = new WishlistRestApi();

export const fetchCards = createAsyncThunk(
    'cards/fetchCards',
    async ({ categoryName, newFilter, page, sort }: FetchCardsParams, { rejectWithValue }) => {
        if (!categoryName) {
            return rejectWithValue("categoryName deve essere definito");
        }

        const mappedCategoryName = categoryMap[categoryName.toLowerCase()];

        if (!mappedCategoryName) {
            return rejectWithValue(`Categoria non riconosciuta: ${categoryName}`);
        }

        // Mappa i criteri di filtro di base
        const criteria = {
            tipoCard: { equals: mappedCategoryName },
            ...(newFilter.criteria || {}),
        };

        // Estrai `property` e `direction` da `sort`
        const [rawProperty = 'numeroEspansione', rawDirection = 'ASC'] = sort ? sort.split(',') : ['numeroEspansione', 'ASC'];

        // Se il tipo è diverso da 'One_piece', il campo di ordinamento deve essere idCard
        const property = categoryName.toLowerCase() === 'one_piece' ? rawProperty : 'idCard';
        const direction = rawDirection.toUpperCase();

        const pageable = {
            pageNumber: page - 1, // Indice pagina 0-based
            pageSize: 15,
            property,
            direction,
        };

        // Suddivisione dei criteri per categoria
        const criteriaOnePiece = categoryName.toLowerCase() === 'one_piece' ? { ...newFilter.criteriaOp } : undefined;
        const criteriaPokemon = categoryName.toLowerCase() === 'pokemon' ? { ...newFilter.criteriaPokemon } : undefined;
        const criteriaDragonballFs = categoryName.toLowerCase() === 'dragonball' ? { ...newFilter.criteriaDragonballFs } : undefined;
        const criteriaLorcana = categoryName.toLowerCase() === 'lorcana' ? { ...newFilter.criteriaLorcana } : undefined;

        // Crea la richiesta di ricerca
        const searchRequest = {
            criteria,
            ...(criteriaOnePiece && { criteriaOnePiece }),
            ...(criteriaPokemon && { criteriaPokemon }),
            ...(criteriaDragonballFs && { criteriaDragonballFs }),
            ...(criteriaLorcana && { criteriaLorcana }),
            pageable,
        };

        const request: FindByCriteriaSpecificCardRequest = {
            searchRequest,
        };

        try {
            const response = await api.findByCriteriaSpecificCardRaw(request);
            const jsonResponse = await response.raw.json();
            return jsonResponse;
        } catch (error) {
            // @ts-ignore
            return rejectWithValue(error.message || 'Errore nella chiamata API');
        }
    }
);



export const addToWishlist = createAsyncThunk(
    'wishlist/addToWishlist',
    async (params: { cartaId: string | undefined, userId: string }, {rejectWithValue}) => {
        const {cartaId, userId} = params;

        if (!userId) {
            toast.error("User not logged in");
            return rejectWithValue("User not logged in");
        }

        const request: AddCardWishlistRequest = {
            wishlistUtenteInsertDTO: {
                cartaId,
                utenteId: userId,
            },
        };

        try {
            await wishListApi.addCardWishlistRaw(request);
            toast.success('Carta aggiunta alla Wishlist!');
        } catch (error: any) {
            toast.error("Errore");
            return rejectWithValue(error.message || 'Errore');
        }
    }
);


const CarteSlice = createSlice({
    name: 'cards',
    initialState,
    reducers: {
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addToWishlist.pending, (state) => {
                state.error = null;
            })
            .addCase(addToWishlist.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload) {
                    state.items.push(action.payload);
                } else {
                    console.error("Payload undefined in addToWishlist.fulfilled");
                }
            })
            .addCase(addToWishlist.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchCards.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchCards.fulfilled, (state, action) => {
                state.cards = action.payload.content;
                state.totalPages = action.payload.totalPages;
                state.isLoading = false;
            })
            .addCase(fetchCards.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Errore durante il recupero delle carte';
            });

    },
});

export const {setCurrentPage} = CarteSlice.actions;

export default CarteSlice.reducer;
