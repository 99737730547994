import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import styles from "./HorizontalMenu.module.css";
import logo from "../../assets/logo.png";
import onePieceLogo from "../../assets/one-piece_logo.png";
import dragonBallLogo from "../../assets/logo_dragonball.png";
import lorcanaLogo from "../../assets/lorcana_logo.png";
import {Avatar, Badge, Button, Divider, IconButton, Menu, MenuItem, Tooltip, Typography,} from "@mui/material";
import {FaBell, FaBriefcase, FaSearch, FaSignInAlt, FaUser, FaUserFriends, FaUserPlus,} from "react-icons/fa";
import {ExpandLess, ExpandMore, People} from "@mui/icons-material";
import axios from "axios";
import {ConfermaAmiciziaRequest, FriendShip, RichiestaAmiciziaDTO, UtenteRestApi} from "../../api";
import {toast} from "react-toastify";
import {SiHomeassistantcommunitystore} from "react-icons/si";
import {menuItemStyles} from "../Card/Filtri-ricerca/FiltriHandler";

const amiciziaApi = new UtenteRestApi();

const HorizontalMenu = () => {
    const [gamesMenuOpen, setGamesMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [userId, setUserId] = useState("");
    const [user, setUser] = useState<any>(null);
    const [notifications, setNotifications] = useState<RichiestaAmiciziaDTO[]>([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const [notificationMenuAnchor, setNotificationMenuAnchor] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [profileMenuAnchor, setProfileMenuAnchor] = useState<null | HTMLElement>(null);

    const isActive = (path: string) => location.pathname === path;

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
        if (storedUser && storedUser.id) {
            setUser(storedUser);
            setUserId(storedUser.id);
        }
    }, []);

    useEffect(() => {
        setGamesMenuOpen(false);
    }, [location.pathname]);

    useEffect(() => {
        if (!userId) return;

        const eventSource = new EventSource(`https://ovuem7qlms6tkdkzuub5bjnrmm.apigateway.eu-milan-1.oci.customer-oci.com/collectoon/sse/amicizie/pending?userId=${userId}`);

        eventSource.addEventListener('friend-request', (event) => {
            const richiesta = JSON.parse(event.data);
            setNotifications((prev) => [richiesta, ...prev]);
            setNotificationCount((prev) => prev + 1);
        });

        eventSource.onmessage = (event) => {
            const richiesta = JSON.parse(event.data);
            setNotifications((prev) => [richiesta, ...prev]);
            setNotificationCount((prev) => prev + 1);
        };

        eventSource.onerror = (err) => {
            console.error("Errore SSE:", err);
            eventSource.close();
        };

        return () => {
            eventSource.close();
        };
    }, [userId]);

    const handleGamesMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        setGamesMenuOpen((prev) => !prev);
        setAnchorEl(event.currentTarget);
    };

    const handleGamesMenuClose = () => {
        setGamesMenuOpen(false);
        setAnchorEl(null);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setProfileMenuAnchor(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuAnchor(null);
    };

    const handleLogout = () => {
        localStorage.removeItem("user");
        setUser(null);
        navigate("/login");
    };

    const handleProfileClick = () => {
        navigate(`/user-profile`);
        handleProfileMenuClose();
    };

    const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setNotificationMenuAnchor(event.currentTarget);
        setNotificationCount(0);
    };

    const handleNotificationMenuClose = () => {
        setNotificationMenuAnchor(null);
    };

    const handleAccept = async (idAmico: string | undefined, idUtente: string | undefined) => {
        if (!idAmico || !idUtente) {
            return;
        }

        const request: ConfermaAmiciziaRequest = {
            idUtente: idUtente,
            idAmico: idAmico,
            conferma: true,
        };

        try {
            const response = await amiciziaApi.confermaAmiciziaRaw(request);
            const jsonResponse = await response.raw.json();

            if (jsonResponse) {
                setNotifications((prev) => prev.filter((notifica) => notifica?.amiciziaUtentiDTO?.friend?.id !== idAmico));
                toast.success("Richiesta di amicizia accettata.");
            } else {
                console.error("Errore durante l'accettazione della richiesta.");
                toast.error("Errore durante l'accettazione della richiesta.");
            }
        } catch (err) {
            console.error("Errore nella chiamata API:", err);
            toast.error("Impossibile accettare la richiesta. Riprova più tardi.");
        }
    };



    const handleReject = async (idUtenteMittente: FriendShip | undefined) => {
        try {
            await axios.post(`http://localhost:8005/api/amicizie/rifiuta`, {
                idUtenteMittente: idUtenteMittente,
                idUtenteDestinatario: userId,
            });
            setNotifications((prev) => prev.filter((notifica) => notifica?.amiciziaUtentiDTO?.id !== idUtenteMittente));
            setNotificationCount((prev) => Math.max(prev - 1, 0));
        } catch (error) {
            console.error("Errore nel rifiutare la notifica:", error);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.topBar}>
                <Link to="/" className={styles.logo}>
                    <img src={logo} alt="Collectoon" />
                </Link>
                <div className={styles.iconsContainer}>
                    {user ? (
                        <>
                            <Tooltip title="Notifiche">
                                <IconButton
                                    className={styles.icon}
                                    onClick={handleNotificationMenuOpen}
                                >
                                    <Badge badgeContent={notificationCount} color="error">
                                        <FaBell />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                anchorEl={notificationMenuAnchor}
                                open={Boolean(notificationMenuAnchor)}
                                onClose={handleNotificationMenuClose}
                                PaperProps={{
                                    style: {
                                        width: '260px',
                                        maxHeight: '300px',
                                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '8px',
                                    },
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        padding: '8px 16px',
                                        fontWeight: 'bold',
                                        color: '#333',
                                        borderBottom: '1px solid #eee',
                                    }}
                                >
                                    Notifiche
                                </Typography>
                                {notifications.length > 0 ? (
                                    <>
                                        {notifications.slice(0, 2).map((notifica, index) => (
                                            <React.Fragment key={index}>
                                                <MenuItem
                                                    sx={{
                                                        padding: '12px 16px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        gap: '6px',
                                                        borderBottom: '1px solid #f0f0f0',
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <Avatar
                                                            sx={{
                                                                marginRight: '10px',
                                                                backgroundColor: '#3f51b5',
                                                                width: '36px',
                                                                height: '36px',
                                                            }}
                                                        >
                                                            <FaUserFriends style={{ color: '#fff' }} />
                                                        </Avatar>
                                                        <div style={{ flex: '1' }}>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{ fontWeight: 'bold', color: '#333', fontSize: '14px' }}
                                                            >
                                                                {notifica.messaggio}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                sx={{ color: '#666', fontSize: '12px' }}
                                                            >
                                                              Da {notifica?.amiciziaUtentiDTO?.user?.username}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            color="success"
                                                            size="small"
                                                            sx={{ fontSize: '10px', padding: '4px 8px', textTransform: 'capitalize' }}
                                                            onClick={() =>
                                                                handleAccept(
                                                                    notifica?.amiciziaUtentiDTO?.friend?.id, // ID dell'amico (destinatario)
                                                                    notifica?.amiciziaUtentiDTO?.user?.id    // ID dell'utente (mittente)
                                                                )
                                                            }
                                                        >
                                                            Accetta
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            size="small"
                                                            sx={{
                                                                fontSize: '10px',
                                                                padding: '4px 8px',
                                                                textTransform: 'capitalize',
                                                                marginLeft: '8px',
                                                            }}
                                                            onClick={() => handleReject(notifica?.amiciziaUtentiDTO?.id)}
                                                        >
                                                            Rifiuta
                                                        </Button>
                                                    </div>
                                                </MenuItem>
                                            </React.Fragment>
                                        ))}
                                        {notifications.length > 2 && (
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    padding: '8px 0',
                                                    borderTop: '1px solid #eee',
                                                }}
                                            >
                                                <Button
                                                    size="small"
                                                    sx={{
                                                        color: '#3f51b5',
                                                        textTransform: 'capitalize',
                                                        fontWeight: 'bold',
                                                        fontSize: '12px',
                                                    }}
                                                    onClick={() => navigate('/all-notifications')}
                                                >
                                                    View All
                                                </Button>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <MenuItem disabled>
                                        <Typography variant="body2" sx={{ color: '#999', textAlign: 'center' }}>
                                            Nessuna nuova notifica
                                        </Typography>
                                    </MenuItem>
                                )}
                            </Menu>

                            <Tooltip title="Wishlist">
                                <Link to="/wishlist-utente" className={styles.icon}>
                                    <FaBriefcase />
                                </Link>
                            </Tooltip>
                            <Tooltip title="Profilo">
                                <IconButton
                                    className={styles.icon}
                                    onClick={handleProfileMenuOpen}
                                >
                                    <FaUser />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                anchorEl={profileMenuAnchor}
                                open={Boolean(profileMenuAnchor)}
                                onClose={handleProfileMenuClose}
                            >
                                <MenuItem onClick={handleProfileClick}  sx={menuItemStyles}>
                                    Il mio Profilo
                                </MenuItem>
                                <MenuItem onClick={handleLogout} sx={menuItemStyles}>Logout</MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <div className={styles.authButtons}>
                            <Tooltip title="Login">
                                <Button
                                    component={Link}
                                    to="/login"
                                    className={styles.loginButton}
                                >
                                    <FaSignInAlt size={22} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Registrati">
                                <Button
                                    component={Link}
                                    to="/registrati"
                                    className={styles.registerButton}
                                >
                                    <FaUserPlus size={22} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.verticalMenu}>
                <Button
                    onClick={handleGamesMenuToggle}
                    endIcon={gamesMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    className={`${styles.menuItem} ${
                        isActive("/explore") ? styles.active : ""
                    }`}
                >
                    <FaSearch style={{ marginRight: "8px" }} />
                    Esplora i TCG
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={gamesMenuOpen}
                    onClose={handleGamesMenuClose}
                >
                    <MenuItem onClick={() => navigate("/cards/one_piece")}>
                        <img
                            src={onePieceLogo}
                            alt="One Piece"
                            className={styles.gameLogo}
                        />
                    </MenuItem>
                    {/*<MenuItem onClick={() => navigate("/cards/pokemon")}>
                        <img
                            src={pokemonLogo}
                            alt="Pokémon"
                            className={styles.gameLogo}
                        />
                    </MenuItem>*/}
                    <MenuItem onClick={() => navigate("/cards/dragonball")}>
                        <img
                            src={dragonBallLogo}
                            alt="Dragon Ball"
                            className={styles.gameLogo}
                        />
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/cards/lorcana")}>
                        <img
                            src={lorcanaLogo}
                            alt="Lorcana"
                            className={styles.gameLogo}
                        />
                    </MenuItem>
                </Menu>

                {user && (
                    <>
                        <Button
                            component={Link}
                            to={`/my-collection/${userId}`}
                            className={`${styles.menuItem} ${
                                isActive(`/my-collection/${userId}`)
                                    ? styles.active
                                    : ""
                            }`}
                        >
                            <FaUserFriends style={{marginRight: "8px"}}/>
                            Le mie Collezioni
                        </Button>
                        <Button
                            component={Link}
                            to="/collections-user/public"
                            className={`${styles.menuItem} ${
                                isActive("/collections-user/public")
                                    ? styles.active
                                    : ""
                            }`}
                        >
                            <People style={{marginRight: "8px"}}/>
                            Vetrina Utenti
                        </Button>
                        <div className={styles.tooltipContainer}>
                            <Button
                                component={Link}
                                to="#"
                                className={`${styles.menuItem} ${styles.pulsanteDisabilitato} ${
                                    isActive("/all-posts") ? styles.active : ""
                                }`}
                                disabled
                            >
                                <SiHomeassistantcommunitystore style={{marginRight: "8px"}}/>
                                Community
                            </Button>
                            <span className={styles.tooltipText}>COMING SOON..</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default HorizontalMenu;
