/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FriendShip
 */
export interface FriendShip {
    /**
     * 
     * @type {string}
     * @memberof FriendShip
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof FriendShip
     */
    friendId?: string;
}

/**
 * Check if a given object implements the FriendShip interface.
 */
export function instanceOfFriendShip(value: object): value is FriendShip {
    return true;
}

export function FriendShipFromJSON(json: any): FriendShip {
    return FriendShipFromJSONTyped(json, false);
}

export function FriendShipFromJSONTyped(json: any, ignoreDiscriminator: boolean): FriendShip {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'friendId': json['friendId'] == null ? undefined : json['friendId'],
    };
}

export function FriendShipToJSON(value?: FriendShip | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'friendId': value['friendId'],
    };
}

