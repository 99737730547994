import {
    CartaDTO,
    CollectionByIdRequest,
    CollectionByIdUtenteRequest, CollezioneCriteria,
    CollezioneDTO,
    CollezioneRestApi,
    type CollezioneUpdateDTO,
    CreateCollectionRequest,
    DeleteCollectionRequest,
    DocumentiRestApi, FindByCriteriaRequest, FindByCriteriaSpecificCardRequest,
    UpdateCollectionRequest, UtenteCriteria
} from "../../api";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {saveAs} from "file-saver";

interface CollezioniState {
    collezioni: CollezioneDTO[];
    carte: CartaDTO[];
    commenti: any[];
    loading: boolean;
    error: string | null;
    backgroundImage: string | null;
    selectedCollezione: CollezioneDTO | null;
    currentPage: number;
    totalPages: number;
}

const initialState: CollezioniState = {
    collezioni: [],
    carte: [],
    commenti: [],
    loading: false,
    error: null,
    backgroundImage: null,
    selectedCollezione: null,
    currentPage: 1,
    totalPages: 0
};

interface FecthCollection {
    newFilter: {
        collezioneCriteria?: any;
    };
    sort?: any;
    page: number;
}

const api = new CollezioneRestApi();
const exportApi = new DocumentiRestApi();

// Fetch Collezioni
export const fetchCollezioni = createAsyncThunk(
    'collezioni/fetchCollezioni',
    async ({idUtente, tipoCard}: { idUtente: string | undefined, tipoCard: any; }, {rejectWithValue}) => {
        if (!idUtente) return rejectWithValue('ID Utente mancante');


        if (tipoCard === undefined) {
            tipoCard = null;
        }


        const apiRequest: CollectionByIdUtenteRequest = {
            userId: idUtente,
            tipoCard: tipoCard
        };

        try {
            const response = await api.collectionByIdUtenteRaw(apiRequest);
            const jsonResponse = await response.raw.json();
            return jsonResponse || [];
        } catch (error) {
            toast.error('Si è verificato un errore con il caricamento dei commenti.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

export const fetchUserCollection = createAsyncThunk(
    'collezioni/fetchUserCollection',
    async ({idCollection}: { idCollection: string }, {rejectWithValue}) => {
        if (!idCollection) {
            return rejectWithValue('ID Collezione mancante');
        }

        const collectionRequest = {
            collectionId: idCollection,
        };

        try {
            const response = await api.collectionByIdRaw(collectionRequest);
            const jsonResponse = await response.raw.json();

            return jsonResponse || {}; // Puoi anche restituire un oggetto vuoto come fallback
        } catch (error) {
            toast.error('Si è verificato un errore con il caricamento della collezione.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

export const findByCriteria = createAsyncThunk(
    'collections/findByCriteria',
    async ({newFilter, page, sort}: FecthCollection) => {



        const utenteCriteria: UtenteCriteria = {
            username: newFilter.collezioneCriteria.utente?.username,
            id: newFilter.collezioneCriteria.utente?.id
                ? {notEquals: newFilter.collezioneCriteria.utente.id}
                : undefined,
        };



        const collezioniCriteria: CollezioneCriteria = {
            nome: newFilter.collezioneCriteria?.criteria.name,
            tipoCard: newFilter.collezioneCriteria?.criteria.tipoTcg,
            privata: {equals: false}, // Campo privato sempre falso
            utente: utenteCriteria,
        };


        const criteria = {
            ...collezioniCriteria,
        };





        const pageable = {
            pageNumber: page - 1, // Indice 0-based
            pageSize: 15,
        };

        const searchRequest = {
            criteria,
            collezioneCriteria: Object.keys(collezioniCriteria).length ? collezioniCriteria : undefined,
            pageable,
        };


        const request: FindByCriteriaRequest = {
            searchRequest,
        };

        const response = await api.findByCriteriaRaw(request);

        const jsonResponse = await response.raw.json();


        return jsonResponse;
    }
);


export const fetchCollezionById = createAsyncThunk(
    'collezioni/fetchCollezionById',
    async ({idCollection}: { idCollection: any; }, {rejectWithValue}) => {
        if (!idCollection) return rejectWithValue('ID Utente mancante');


        const apiRequest: CollectionByIdRequest = {
            collectionId: idCollection,
        };


        try {
            const response = await api.collectionByIdRaw(apiRequest);
            const jsonResponse = await response.raw.json();
            return jsonResponse || null;
        } catch (error) {
            toast.error('Si è verificato un errore con il caricamento dei commenti.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

//ADD COLLECTION

export const addCollection = createAsyncThunk(
    'collezioni/addCollection',
    async (collezione: CollezioneDTO, {rejectWithValue}) => {
        const createRequest: CreateCollectionRequest = {
            collezioneInsertDTO: collezione
        };
        try {
            const response = await api.createCollectionRaw(createRequest);
            return await response.raw.json();
        } catch (error) {
            toast.error('Si è verificato un errore durante la creazione della collezione.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

// Update Comment
export const updateCollection = createAsyncThunk(
    'collezioni/updateCollection',
    async (collezione: CollezioneDTO, {rejectWithValue}) => {
        const updateRequest: UpdateCollectionRequest = {
            collezioneUpdateDTO: collezione
        };

        try {
            const response = await api.updateCollectionRaw(updateRequest);
            return collezione;
        } catch (error) {
            toast.error('Si è verificato un errore con l\'aggiornamento del commento.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

export const deleteCollection = createAsyncThunk(
    'collezioni/deleteCollection',
    async (id: string | undefined, {rejectWithValue}) => {
        if (!id) return rejectWithValue('ID collezione mancante');
        const request: DeleteCollectionRequest = {
            idCollection: id
        };

        try {
            await api.deleteCollection(request);
            toast.success('Collezione eliminata con successo!');
            return id;
        } catch (error) {
            toast.error('Si è verificato un errore con l\'aggiornamento del commento.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

// Upload Image Cover
export const uploadImageCover = createAsyncThunk(
    'collezioni/uploadImageCover',
    async ({idCollection, file}: { idCollection: string | undefined; file: File | null }, {
        rejectWithValue,
        dispatch
    }) => {
        if (!idCollection) return rejectWithValue('ID collezione mancante');

        try {
            if (file && file.size > 0) {
                const formData = new FormData();
                formData.append('file', file);

                const response = await fetch(`http://localhost:8005/api/collection/${idCollection}/cover`, {
                    method: 'POST',
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error('Errore durante il caricamento dell\'immagine');
                }

                const updatedCollection = await response.json();

                return updatedCollection;
            } else {
                const updateDTO: CollezioneUpdateDTO = {
                    id: idCollection,
                    imgCopertina: undefined,
                    modificaCopertina: true
                };


                const updatedCollection = await dispatch(updateCollection(updateDTO)).unwrap();

                return updatedCollection;
            }
        } catch (error) {
            toast.error('Si è verificato un errore durante il caricamento dell\'immagine di copertura.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

export const fetchRecentCollection = createAsyncThunk(
    'collezioni/fetchCollezioni',
    async (_: void, {rejectWithValue}) => {

        try {
            const response = await api.ultimeCollezioniPubblicheRaw();
            const jsonResponse = await response.raw.json();
            return jsonResponse || [];
        } catch (error) {
            toast.error('Si è verificato un errore con il caricamento dei commenti.');
            return rejectWithValue(error instanceof Error ? error.message : 'Errore sconosciuto');
        }
    }
);

export const exportCollectionToPDF = createAsyncThunk(
    'collezioni/exportCollectionToPDF',
    async ({collezioneId, nome}: any) => {
        const response = await exportApi.exportCollezioneToPDFRaw({collezioneId});
        const blob = await response.raw.blob();
        saveAs(blob, `${nome}-${collezioneId}.pdf`);
    }
);


const collezioniSlice = createSlice({
    name: 'collezioni',
    initialState,
    reducers: {
        setCollezioni: (state, action: PayloadAction<CollezioneDTO[]>) => {
            state.collezioni = action.payload;
        },
        findByCriteriaSuccess: (state, action) => {
            state.collezioni = action.payload.content;
            state.totalPages = action.payload.totalPages;
        },
        setSelectedCollezione: (state, action: PayloadAction<CollezioneDTO | null>) => {
            state.selectedCollezione = action.payload;
        },
        updateCollectionReducer: (state, action: PayloadAction<CollezioneDTO>) => {
            const index = state.collezioni.findIndex(collezione => collezione.id === action.payload.id);
            if (index !== -1) {
                state.collezioni[index] = action.payload;
            }
        },
        deleteCollectionReducer: (state, action: PayloadAction<number | undefined>) => {
            state.collezioni = state.collezioni.filter(comment => comment.id !== action.payload);
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCollezioni.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCollezioni.fulfilled, (state, action) => {
                state.loading = false;
                state.collezioni = action.payload;
            })
            .addCase(fetchCollezioni.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchCollezionById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCollezionById.fulfilled, (state, action) => {
                state.loading = false;
                state.selectedCollezione = action.payload;
            })
            .addCase(fetchCollezionById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(addCollection.fulfilled, (state, action) => {
                state.loading = false;
                state.collezioni.push(action.payload);
            })
            .addCase(updateCollection.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateCollection.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.collezioni.findIndex(comment => comment.id === action.payload.id);
                if (index !== -1) {
                    state.collezioni[index] = action.payload;
                }
            })
            .addCase(findByCriteria.fulfilled, (state, action) => {
                console.log('Dati ricevuti dal server:', action.payload); // Debug
                state.collezioni = action.payload.content || [];
                state.totalPages = action.payload.totalPages || 0;
                state.loading = false;
            })
            .addCase(findByCriteria.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Errore nella ricerca';
            })
            .addCase(updateCollection.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(deleteCollection.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteCollection.fulfilled, (state, action) => {
                state.loading = false;
                state.collezioni = state.collezioni.filter(comment => comment.id !== action.payload);
            })
            .addCase(deleteCollection.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(uploadImageCover.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(uploadImageCover.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(uploadImageCover.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    }
});
export const {
    setCollezioni,
    setSelectedCollezione,
    deleteCollectionReducer,
    updateCollectionReducer,
    findByCriteriaSuccess,
} = collezioniSlice.actions;
export const {setCurrentPage} = collezioniSlice.actions;
export default collezioniSlice.reducer;
