/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FriendShip } from './FriendShip';
import {
    FriendShipFromJSON,
    FriendShipFromJSONTyped,
    FriendShipToJSON,
} from './FriendShip';
import type { UtenteDTO } from './UtenteDTO';
import {
    UtenteDTOFromJSON,
    UtenteDTOFromJSONTyped,
    UtenteDTOToJSON,
} from './UtenteDTO';

/**
 * 
 * @export
 * @interface AmiciziaUtentiDTO
 */
export interface AmiciziaUtentiDTO {
    /**
     * 
     * @type {FriendShip}
     * @memberof AmiciziaUtentiDTO
     */
    id?: FriendShip;
    /**
     * 
     * @type {UtenteDTO}
     * @memberof AmiciziaUtentiDTO
     */
    user?: UtenteDTO;
    /**
     * 
     * @type {UtenteDTO}
     * @memberof AmiciziaUtentiDTO
     */
    friend?: UtenteDTO;
    /**
     * 
     * @type {string}
     * @memberof AmiciziaUtentiDTO
     */
    statoAmicizia?: AmiciziaUtentiDTOStatoAmiciziaEnum;
    /**
     * 
     * @type {Date}
     * @memberof AmiciziaUtentiDTO
     */
    createdAt?: Date;
}


/**
 * @export
 */
export const AmiciziaUtentiDTOStatoAmiciziaEnum = {
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Blocked: 'BLOCKED'
} as const;
export type AmiciziaUtentiDTOStatoAmiciziaEnum = typeof AmiciziaUtentiDTOStatoAmiciziaEnum[keyof typeof AmiciziaUtentiDTOStatoAmiciziaEnum];


/**
 * Check if a given object implements the AmiciziaUtentiDTO interface.
 */
export function instanceOfAmiciziaUtentiDTO(value: object): value is AmiciziaUtentiDTO {
    return true;
}

export function AmiciziaUtentiDTOFromJSON(json: any): AmiciziaUtentiDTO {
    return AmiciziaUtentiDTOFromJSONTyped(json, false);
}

export function AmiciziaUtentiDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AmiciziaUtentiDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : FriendShipFromJSON(json['id']),
        'user': json['user'] == null ? undefined : UtenteDTOFromJSON(json['user']),
        'friend': json['friend'] == null ? undefined : UtenteDTOFromJSON(json['friend']),
        'statoAmicizia': json['statoAmicizia'] == null ? undefined : json['statoAmicizia'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
    };
}

export function AmiciziaUtentiDTOToJSON(value?: AmiciziaUtentiDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': FriendShipToJSON(value['id']),
        'user': UtenteDTOToJSON(value['user']),
        'friend': UtenteDTOToJSON(value['friend']),
        'statoAmicizia': value['statoAmicizia'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}

