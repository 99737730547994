import React from 'react';
import styles from './UserCollection.module.css';
import { Link } from "react-router-dom";

interface CardComponentProps {
    id: string;
    nomeCollezione: string;
    tipoCollection: string;
    nomeUtente: string;
    idUser: string; // Nuova prop che identifica l'utente
    imgCopertina?: string | null;
}

const UserCollection: React.FC<CardComponentProps> = ({
                                                          id,
                                                          nomeCollezione,
                                                          tipoCollection,
                                                          nomeUtente,
                                                          idUser,
                                                          imgCopertina
                                                      }) => {
    const backgroundImageStyle = imgCopertina
        ? {
            backgroundImage: `url('data:image/jpeg;base64,${imgCopertina}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            filter: 'brightness(0.9)',
        }
        : {
            backgroundColor: '#f5f5f5',
        };

    return (
        <div className={styles.cardContainer}>
            <div className={styles.card} style={backgroundImageStyle}>
                {/* Segnalibro */}
                <div className={styles.ribbon}>
                    <span className={styles.ribbonText}>{tipoCollection}</span>
                </div>
                {/* Overlay contenuti */}
                <div className={styles.cardOverlay}>
                    <h3 className={styles.cardTitle}>{nomeCollezione}</h3>
                    <p className={styles.cardWants}>
                        <div className={styles.chip}>By {nomeUtente}</div>
                    </p>
                    <Link
                        to={`/collections-user-detail/${id}`}
                        className={styles.cardButton}
                    >
                        Vedi
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default UserCollection;
