import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Home from './pages/Home';
import ListCard from './pages/ListCard';
import HorizontalMenu from './components/HorizontalMenu/HorizontalMenu';
import './App.css';
import Footer from "./components/Footer/footer";
import MyCollection from "./pages/MyCollection";
import PrivateRoute from "./auth/PrivateRoute";
import {AuthProvider} from './auth/AuthContext';
import Login from "./pages/login";
import MyCollectionDetail from "./pages/detail/MyCollectionDetail";
import ListCollectionsPublic from "./pages/ListCollectionsPublic";
import ListaObiettiviPage from "./pages/ListaObiettiviPage";
import WishlistPage from "./pages/WishlistPage";
import UserProfile from "./pages/UserProfile";
import TermsPage from "./pages/footerpage/TermsPage";
import Registrazione from './pages/registrazione';
import ConfermaAccount from "./components/ConfermaAccount/ConfermaAccount";
import CambiaPassword from "./components/password/CambiaPassword";
import RecoveryPassword from "./components/password/RecoveryPassword";
import DettagliUtente from "./components/UserProfile/Dettagli-Utente/Dettagli-Utente";
import CommunityPage from "./pages/CommunityPage";
import AllPostsPage from "./pages/CommunityPostPage";
import AllCommunitiesPage from "./pages/AllCommunityPage";

const mockCommunityData = {
    name: 'Mock Community',
    membersCount: 1234,
    isMember: true,
    onJoinLeave: () => alert('Unisciti/Lascia premuto'),
    createPost: (content: string) => alert(`Post creato: ${content}`),
    posts: [
        { id: '1', title: 'Benvenuti!', content: 'Questo è il primo post nella community.' },
        { id: '2', title: 'Regole della community', content: 'Sii gentile e rispettoso.' },
    ],
    description: 'Questa è una community mockata per i test.',
    rules: ['Non fare spam', 'Sii gentile', 'Rispetta tutti'],
    moderators: ['Admin1', 'Mod1', 'Mod2'],
};
const mockCommunityDataPost = {
    communityName: 'Mock Community',
    posts: [
        { id: '1', title: 'Benvenuti nella Community!', content: 'Questo è il primo post della community.' },
        { id: '2', title: 'Regole della Community', content: 'Sii gentile, non fare spam, rispetta gli altri.' },
        { id: '3', title: 'Discussione Generale', content: 'Inizia una conversazione con altri membri!' },
    ],
};

const mockPosts = [
    { id: '1', title: 'Benvenuti nella Community!', content: 'Questo è il primo post.', subCommunityName: 'Italia', subCommunityImage: 'https://via.placeholder.com/40' },
    { id: '2', title: 'Regole della Community', content: 'Sii gentile, non fare spam.', subCommunityName: 'Programmazione', subCommunityImage: 'https://via.placeholder.com/40' },
    { id: '3', title: 'Discussione Generale', content: 'Inizia una conversazione.', subCommunityName: 'Giochi', subCommunityImage: 'https://via.placeholder.com/40' },
];

const App: React.FC = () => {
    return (
        <AuthProvider>
            <Router>
                <div className="App global-background">
                    <HorizontalMenu />
                    <main>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/cards/:categoryName" element={<ListCard />} />
                            <Route path="/registrati" element={<Registrazione />}/>
                            <Route path="/terms" element={<TermsPage />} />
                            <Route path="/conferma-account/:token/" element={<ConfermaAccount />} />
                            <Route path="/recupera-password" element={<RecoveryPassword />} />
                            <Route path="/cambia-password/:token/" element={<CambiaPassword />} />
                            <Route element={<PrivateRoute />}>
                                <Route path="/my-collection/:idUser" element={<MyCollection />} />
                                <Route path="/my-collection/:idUser/:tipoCard?" element={<MyCollection />} />
                                <Route path="/lista-obiettivi" element={<ListaObiettiviPage />} />
                                <Route path="/my-collection-detail/:idCollection/:tipoCard" element={<MyCollectionDetail showExportButton={true}/>} />
                                <Route path="/collections-user/public" element={<ListCollectionsPublic />} />
                                <Route path="/collections-user-detail/:idCollection" element={<MyCollectionDetail showExportButton={false}/>} />
                                <Route path="/wishlist-utente" element={<WishlistPage />} />
                                <Route path="/user-profile" element={<UserProfile />} />
                                {/*<Route path="/community/:idSubCommunity" element={<CommunityPage/>} />*/}
                                {/*<Route path="/all-posts" element={<AllPostsPage/>} />*/}
                                {/*<Route path="/all-comunity" element={<AllCommunitiesPage/>} />*/}
                                <Route path="/user-profile/settings" element={<UserProfile activeMenuItem="settings" />} />
                                <Route path="/user-detail/:idUser/:idCollection" element={<DettagliUtente/>} />
                                <Route path="/user-detail/:idUser" element={<DettagliUtente/>} />
                            </Route>
                        </Routes>
                    </main>
                    <Footer />
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;
